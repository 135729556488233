import * as React from 'react';
import Layout from '../../components/layout';
import Article from '../../components/article';
import { MailerLiteEmbed } from '../../components/mailerlite-embed';
import { Seo } from '../../components/seo';

const Mentorship = ({ location }) => {
  const pageTitle = 'Become a Web Developer with Help From a Mentor';
  return (
    <Layout pageType="landing" pageTitle={pageTitle}>
      <Seo
        pageTitle={pageTitle}
        description="Itʼs hard to break into web development. I want to help you get there."
        follow={false}
        index={false}
        pathname={location.pathname}
      />
      <Article>
        <h1 className="fancy">
          You want to become a web developer.{' '}
          <span style={{ color: '#6d2959' }}>I want to help.</span>
        </h1>
        <p>
          I'm not currently offering live one-to-one mentorship, but I answer
          every email I get. If you need help, just sign up for my email list.
          👇 I'll send you resources to help you get going. Just hit reply on
          any email and ask me any questions you have. I'd love to help you
          overcome the specific challenges you're up against.
        </p>
        <MailerLiteEmbed
          formUrl="https://dashboard.mailerlite.com/forms/90414/61549854400185537/share"
          height="505px"
        />
      </Article>
    </Layout>
  );
};
export default Mentorship;
